<template>
    <div>
        <div v-if="loading" class="panel panel-default text-center">

            <div class="panel-heading">
                {{ $t("analysis:tabs:administrative_spatial_acts") }}
            </div>

            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <p>{{ $t('aa:acts:collect')}}</p>
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>

        </div>

        <div v-else-if="acts && spatial_acts" class="panel panel-default">

            <!-- START: Map section -->
            <div class="panel-heading">
                {{ $t("analysis:tabs:administrative_spatial_acts") }}
                <div class="panel-action">
                    <a class="btn btn-default pull-right" @click.prevent="getActs(true)">{{ $t('aa:acts:re_collect')}}</a>
                </div>
            </div>
            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <k-wms-leaflet-map
                        :center="center"
                        :extraLayers="acts_layers"
                        @click="handleClick"
                        ref="map"
                        :fullscreen="true"
                        :zoom="18"
                        :maxZoom="20"
                        :polygons="parcelPolygons"
                        :polygonPopupEnabled="false"
                    >

                        <template v-slot:markers>

                            <l-marker
                                v-if="map_clicked_acts"
                                :lat-lng="map_clicked_acts.gps_point.coordinates"
                                ref="clickmarker"
                            >

                                <l-popup
                                    :options="{className: 'acts-custom-leaflet-popup'}"
                                    ref="popup"
                                >
                                    <span v-if="map_clicked_acts.data" class="vertical-table">
                                        <h3>{{ $t('aa:permit:construction')}}</h3>
                                        <administrative-acts-vertical-table
                                            v-if="map_clicked_acts.data.administrative.gradbena_dovoljenja.length"
                                            :acts="map_clicked_acts.data.administrative.gradbena_dovoljenja"
                                        />
                                        <div v-else>{{$t('aa:permit:construction:not_available')}}</div>
                                        
                                        <h3>{{ $t('aa:permit:usage')}}</h3>
                                        <administrative-acts-vertical-table
                                            v-if="map_clicked_acts.data.administrative.uporabna_dovoljenja.length"
                                            :acts="map_clicked_acts.data.administrative.uporabna_dovoljenja"
                                        />
                                        <div v-else class="m-b-20">{{ $t('aa:permit:usage:not_available')}}</div>

                                        <spatial-acts-vertical-table
                                            v-if="map_clicked_acts.data.spatial"
                                            :acts="map_clicked_acts.data.spatial"
                                        />
                                        <div v-else class="m-b-20">Not available</div>
                                    </span>

                                    <span v-else>
                                        <p>{{ $t('general:loading')}}</p>
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </span>
                                </l-popup>

                            </l-marker>
                        
                        </template>

                    </k-wms-leaflet-map>
                    <div class="p-0 text-center"><slot name="mapRights"></slot></div>
                </div>
            </div>
            <!-- END: Map section -->

            <!-- START: Administrative acts section -->
            <div class="panel-heading">
                {{ $t('aa:acts') }}
            </div>
            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-12 overflow-auto">
                            <administrative-acts-table
                                :acts="acts"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Administrative acts section -->

            <!-- START: Administrative acts section -->
            <div class="panel-heading">
                {{ $t("spatial_acts") }}
            </div>
            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-12">
                            <spatial-acts-table
                                :acts="spatial_acts"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="panel panel-default">

            <div class="panel-heading">
              {{ $t('aa:acts')}}
                <div class="panel-action">
                    <a class="btn btn-default pull-right" @click.prevent="getActs(true)">{{ $t('aa:acts:re_collect')}}</a>
                </div>
            </div>

            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <p>{{ $t('general:error2')}}</p>
                    </div>
                </div>
            </div>

        </div>

        <notifications 
            group="warn"
        />

    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import ApiCalls from '@/components/utils/api.calls.js';

import AdministrativeActsTable from './AdministrativeActsTable'
import AdministrativeActsVerticalTable from './AdministrativeActsVerticalTable'
import SpatialActsTable from './SpatialActsTable'
import SpatialActsVerticalTable from './SpatialActsVerticalTable'

import KWMSLeafletMap from '@/components/k-leaflet-map/KWMSLeafletMap'
import { LMarker, LPopup } from 'vue2-leaflet';


export default {
    props: ['analysis_session_id'],
    components: {
        AdministrativeActsVerticalTable,
        AdministrativeActsTable,
        SpatialActsTable,
        SpatialActsVerticalTable,
        LMarker,
        LPopup,
        "k-wms-leaflet-map": KWMSLeafletMap
    },
    data: function () {
        return {
            session_data: null,
            loading: true,
            acts: null,
            spatial_acts: null,
            map_clicked_acts: null,

            parcelPolygons: [],

            // API urls
            base_acts_url: "/amas/analysis/analysis/api/acts/",
            map_acts_url: "/amas/real-estate/api/acts",
            base_spatial_acts_url: "/amas/analysis/analysis/api/spatial-acts/",
            spatial_map_acts_url: "/amas/real-estate/api/spatial-acts",
        
            acts_layers: [
                {
                    name: 'Centroidi upravnih aktov',
                    layers: 'SI.MOP.GRAD:UPRAVNI_AKTI',
                    transparent: true,
                    url: 'https://storitve.eprostor.gov.si/ows-pub-wms/wms?',
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },
                {
                    name: 'Parcele upravnih aktov',
                    layers: 'SI.MOP.GRAD:UPRAVNI_AKTI_PARCELE',
                    transparent: true,
                    url: 'https://storitve.eprostor.gov.si/ows-pub-wms/wms?',
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    maxZoom: 19,
                },

                // START: Občinski prostorski načrt layers
                {
                    name: 'Namenska raba prostora',
                    layers: 'SI.MOP.PA:PLAN_POS_NAM_RABE_PPIP_VELJ',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    styles: "eplan_nam_raba_skupna_opacity",
                },
                {
                    name: 'Namenska raba prostora - Oznake',
                    layers: 'SI.MOP.PA:PLAN_POS_NAM_RABE_PPIP_VELJ',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    styles: "eplan_nam_raba_skupna_oznake",
                },
                {
                    name: 'Enote urejanja prostora - Oznake',
                    layers: 'SI.MOP.PA:PLAN_POS_OBMOCJA_EUP_VELJ',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: true,
                    format: 'image/png',
                    styles: "eplan_obmocja_eup_oznake",
                },
                // END: Občinski prostorski načrt layers

                // START: Spatial planning layers
                // Zaključeno
                {
                    name: `${this.$t('spatial_acts:sprs')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "sprs_zakljucen",
                },
                {
                    name: `${this.$t('spatial_acts:dpn')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "dpn_zakljucen",
                },
                {
                    name: `${this.$t('spatial_acts:rpp')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "rpp_zakljucen",
                },
                {
                    name: `${this.$t('spatial_acts:opp')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "opp_zakljucen",
                },
                {
                    name: `${this.$t('spatial_acts:opn')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "opn_zakljucen",
                },
                {
                    name: `${this.$t('spatial_acts:oupnk')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "oupnk_zakljucen",
                },
                {
                    name: `${this.$t('spatial_acts:oppn')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "oppn_zakljucen",
                },
                {
                    name: `${this.$t('spatial_acts:lp')} (${this.$t('spatial_acts:finished')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "lp_zakljucen",
                },
                // V pripravi
                {
                    name: `${this.$t('spatial_acts:sprs')} (${this.$t('spatial_acts:in_progress')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "sprs_priprava",
                },
                // {
                //     name: `${this.$t('spatial_acts:dpn')} (${this.$t('spatial_acts:in_progress')})`,
                //     layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                //     transparent: true,
                //     url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                //     type: 'overlay',
                //     visible: false,
                //     format: 'image/png',
                //     styles: "dpn_priprava",
                // },
                {
                    name: `${this.$t('spatial_acts:rpp')} (${this.$t('spatial_acts:in_progress')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "rpp_priprava",
                },
                {
                    name: `${this.$t('spatial_acts:opp')} (${this.$t('spatial_acts:in_progress')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "opp_priprava",
                },
                {
                    name: `${this.$t('spatial_acts:opn')} (${this.$t('spatial_acts:in_progress')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "opn_priprava",
                },
                {
                    name: `${this.$t('spatial_acts:oupnk')} (${this.$t('spatial_acts:in_progress')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "oupnk_priprava",
                },
                {
                    name: `${this.$t('spatial_acts:oppn')} (${this.$t('spatial_acts:in_progress')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "oppn_priprava",
                },
                {
                    name: `${this.$t('spatial_acts:lp')} (${this.$t('spatial_acts:in_progress')})`,
                    layers: 'SI.MOP.PA:PLAN_EVT_OBMOCJA_PA',
                    transparent: true,
                    url: 'https://sdi.eprostor.gov.si/ows-kn-wms/wms',
                    type: 'overlay',
                    visible: false,
                    format: 'image/png',
                    styles: "lp_priprava",
                },
                // END: Spatial planning layers
            ]
        }
    },
    methods: {
        getSessionData: function(){
            this.loading = true;
            ApiService
                .get(`/amas/analysis/analysis/api/${this.analysis_session_id}`)
                .then(response => {
                    // remove units from data
                    this.session_data = {
                        ...response.data,
                        properties: response.data.properties.filter(property => property.real_estate.parcel)
                    };
                    this.loading = false;
                    this.getActs();
                })
                .catch(error => {
                    throw error;
                })
        },
        getActs: async function(force_update=false) {
            this.acts = []
            this.spatial_acts = []

            const params = { params: { force_update: force_update } }

            for (const property of this.session_data.properties) {
                this.loading = true;
                try {
                    const [responseAdministrative, responseSpatial] = await Promise.all([
                        ApiService.get(`${this.base_acts_url}${property.id}`, params),
                        ApiService.get(`${this.base_spatial_acts_url}${property.id}`, params),
                    ])
                    this.acts.push(responseAdministrative.data);
                    this.spatial_acts.push(responseSpatial.data);
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                    this.$notify({
                        group: 'warn',
                        type: 'warn',
                        title: 'Opozorilo',
                        text: (error.response && error.response.data) ? error.response.data.message : "Napaka pri pridobivanju aktov",
                    });
                }
            }
            this.getAnalysisParcelGeometries();
        },
        handleClick: async function(e) {
            let coordinates = [e.latlng.lat, e.latlng.lng];
            const requestParams = {
                point: { "type": "Point", "coordinates": [coordinates[1], coordinates[0]] }
            }

            this.map_clicked_acts = {
                gps_point: {"type": "Point", "coordinates": coordinates},
                data: null
            };
            this.$nextTick(() => {this.$refs.clickmarker.mapObject.openPopup();});

            Promise.all([
                ApiService.get(this.map_acts_url, { params: requestParams }),
                ApiService.get(this.spatial_map_acts_url, { params: requestParams }),
            ])
            .then(([responseAdministrative, responseSpatial]) => {
                this.map_clicked_acts.data = {
                    administrative: responseAdministrative.data,
                    spatial: responseSpatial.data
                }
            })
            .catch(error => {
                this.$notify({
                    group: 'warn',
                    type: 'warn',
                    title: 'Opozorilo',
                    text: (error.response && error.response.data) ? error.response.data.message : "Napaka pri pridobivanju aktov",
                });
                throw error;
            })
        },
        getAnalysisParcelGeometries: async function() {
            for (const property of this.session_data.properties) {
                const response = await ApiCalls.getRealEstateGeometry(property.real_estate.re_key)
                    const poly = response.geom_swapped;
                    poly.property_id = response.property_id;
                    poly.style = {
                        stroke: true,
                        fillOpacity: 0,
                        edgeColor: '#FF0000',
                    }
                    this.parcelPolygons.push(poly);
            }
            this.fitPolygonsToMap();
        },
        fitPolygonsToMap() {
            this.$nextTick(() => {
                const polygonCoordinates = this.parcelPolygons.flatMap(c => c.coordinates)
                this.$refs.map.leafletMapObject.fitBounds(
                    polygonCoordinates,
                    {padding: [50, 50]}
                )
            })
        },
    },
    async mounted() {
        this.getSessionData();
    },
    computed: {
        center() {
            if (this.session_data && this.session_data.main_property.real_estate && this.session_data.main_property.real_estate.gps) {
                return this.session_data.main_property.real_estate.gps;
            }
            return null;
        },
    }
}
</script>

<style scoped>
.spatial-acts {
    margin-bottom: 1.5em;
}

.acts-custom-leaflet-popup {
    width: 300px;
}

.acts-custom-leaflet-popup .leaflet-popup-content{
    width: auto;
    margin: 5px;
}

.table > tbody + tbody {
    border-top: none;
}

.vertical-table h3:not(:first-child) {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
}
</style>
