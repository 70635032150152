<template>
    <div>
        <h3>{{ $t("aa:municipality:acts") }}</h3>

        <!-- Namenska raba in podrobni prostorski izvedbeni pogoji -->
        <b class="m-b-5">{{ $t("spatial_acts:dedicated_usage_detailed_spatial_implementation_conditions") }}</b>
        <table class="table table-bordered table-responsive">
            <tbody v-for="(act, inx) in formattedDedicatedUsages" :key="`nam_rab_ppip_${inx}`">
                <tr>
                    <th>{{ $t("spatial_acts:dedicated_usage_tag") }}</th>
                    <td>{{ act.POD_NAM_RABA_OZNAKA }}</td>
                </tr>
                <tr>
                    <th>{{ $t("spatial_acts:dedicated_usage_detailed_tag") }}</th>
                    <td>{{ act.POD_NAM_RABA_NAZIV_DODATEK }}</td>
                </tr>
                <tr>
                    <th>{{ $t("spatial_acts:dedicated_usage_level") }}</th>
                    <td>{{ act.POD_NAM_RABA_RAVEN }}</td>
                </tr>
                <tr>
                    <th>{{ $t("general:description") }}</th>
                    <td>{{ act.POD_NAM_RABA_NAZIV }}</td>
                </tr>
                <tr>
                    <th>{{ $t("spatial_acts:act_number") }}</th>
                    <td>{{ act.STEVILKA }}</td>
                </tr>
            </tbody>
        </table>

        <h3>{{ $t("aa:spatial_planning") }}</h3>
        <div v-for="(sectionActs, sectionTitle) in filteredActs" :key="`section_${sectionTitle}`">
            <b class="m-b-5">{{ getTranslation(sectionTitle) }}</b>
            <table class="table table-bordered table-responsive">
                <tbody v-for="(act, inx) in sectionActs" :key="`act_${sectionTitle}_${inx}`">
                    <tr>
                        <th>{{ $t("spatial_acts:act_number") }}</th>
                        <td>{{ act.STEVILKA }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t("general:name") }}</th>
                        <td>
                            <a v-if="act.POSTOPEK_EPL_ID" :href="getPostopekHref(act)" target="_blank">
                                {{ act.NAZIV_AKTA }}
                            </a>
                            <template v-else>
                                {{ act.NAZIV_AKTA }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ $t("spatial_acts:date_accepted") }}</th>
                        <td>{{ act.DATUM_SPREJEMA }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t("spatial_acts:date_published") }}</th>
                        <td>{{ act.DATUM_OBJAVE }}</td>
                    </tr>
                    <tr>
                        <th>{{ $t("spatial_acts:date_validity_start") }}</th>
                        <td>{{ act.DATUM_ZAC_VELJAVNOSTI }}</td>
                    </tr>
                    <tr v-if="eupMap && eupMap[act.STEVILKA]">
                        <th>{{ $t("spatial_acts:spatial_planning_unit_tag") }}</th>
                        <td>{{ eupMap[act.STEVILKA].join(", ") }}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import spatialactsmixin from './spatialactsmixin';

export default {
    props: ['acts'],
    mixins: [spatialactsmixin],
    computed: {
        filteredActs() {
            const filteredActs = {};
            
            for (const key in this.acts["acts"]) {
                if (this.acts["acts"][key].length > 0) {
                    filteredActs[key] = this.acts["acts"][key];
                }
            }
            return filteredActs;
        },
        eupMap() {
            const map = {};
            for (const eup of this.acts.opn_additional["eup"]) {
                if (!map[eup.STEVILKA])
                    map[eup.STEVILKA] = [eup.NAZIV];    
                else
                    map[eup.STEVILKA].push(eup.NAZIV);
            }
            return map;
        },
        formattedDedicatedUsages() {
            return this.acts.opn_additional["nam_rab_ppip"];
        },
    }
}
</script>

<style scoped>
h3 {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
}
</style>
